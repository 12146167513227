/* eslint-disable @typescript-eslint/ban-ts-comment */
import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { dispatch } from 'src/redux/store';
import {
	addCompanyBranchThunk,
	goBackOnUserOnboarding,
	selectUser,
	updateUser,
	updateUserOnboarding,
} from 'src/redux/slices/auth';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import useFirms from 'src/hooks/useFirms';
import { getBranchRef } from 'src/utils/firebase';
import { useNavigate } from 'react-router';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/system';
import {
	AuthPage,
	CompanyBrandCard,
	ContinueButton,
	StyledGoogleAddressAutocomplete,
	PhoneTextInput,
	TextInput,
} from '../styles';
import { OnboardingNewBranchFormType } from './types';
import { onboardingNewBranchValidationSchema } from './validation';
import OnboardingFooter from '../OnboardingFooter';

const OnboardingNewBranch = () => {
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const { fetchBranches } = useFirms();
	const form = useForm<OnboardingNewBranchFormType>({
		resolver: yupResolver(onboardingNewBranchValidationSchema),
	});

	const handleSubmit = async (data: OnboardingNewBranchFormType) => {
		try {
			if (!user.business) return;

			setIsLoading(true);

			const newBranch = await dispatch(
				addCompanyBranchThunk({
					...data,
					emails: [data.email],
					companyId: user.business.id,
					address: data.address?.formattedAddress ? data.address : null,
				}),
			).unwrap();

			await Promise.all([
				dispatch(updateUserOnboarding({ newBranch: true, info: false })),
				dispatch(updateUser({ branch: getBranchRef(user.business.id, newBranch.id) })),
			]);
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (user.business) fetchBranches(user.business.id);
	}, [user.professions]);

	const handleCancel = async () => {
		await dispatch(goBackOnUserOnboarding());
		navigate('/onboarding/select-company');
	};

	return (
		<FormProvider {...form}>
			<Page title='Login'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<CompanyBrandCard title='I work at...'>
							<Box display='flex' flexDirection='column' gap='15px'>
								<TextInput name='name' placeholder='Enter branch name' />
								<TextInput name='website' placeholder='Enter branch website' />
								<TextInput name='email' placeholder='Enter branch email' />
								<PhoneTextInput name='telephone' placeholder='Enter branch landline' />
								<StyledGoogleAddressAutocomplete
									name='address.formattedAddress'
									label='Select an address(optional)'
									placeholder='Enter branch address'
									setValue={form.setValue}
									clearErrors={form.clearErrors}
								/>
							</Box>
							<Box display='flex' gap='10px' width='100%'>
								<ContinueButton variant='outlined' fullWidth loading={isLoading} onClick={handleCancel}>
									Cancel
								</ContinueButton>
								<ContinueButton variant='contained' fullWidth type='submit' loading={isLoading}>
									Submit
								</ContinueButton>
							</Box>
							<OnboardingFooter />
						</CompanyBrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default OnboardingNewBranch;
