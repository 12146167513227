import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BrandCard } from 'src/components';
import { dispatch, useSelector } from 'src/redux/store';
import { selectUser, updateUser, updateUserOnboarding } from 'src/redux/slices/auth';
import { UserOnboardingStatus } from 'src/types';
import { useNavigate } from 'react-router';
import { AuthPage, ContinueButton, TextInput } from '../styles';
import { OnboardingInfoFormType } from './types';
import { onboardingInfoValidationSchema } from './validation';
import OnboardingFooter from '../OnboardingFooter';

const OnboardingInfo = () => {
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const form = useForm<OnboardingInfoFormType>({
		resolver: yupResolver(onboardingInfoValidationSchema),
		defaultValues: {
			email: user.email ?? null,
			givenNames: user.givenNames,
			lastNames: user.lastNames,
			mobile: user.telephone ?? null,
		},
	});

	const handleSubmit = async (data: OnboardingInfoFormType) => {
		await dispatch(updateUserOnboarding({ info: true, status: UserOnboardingStatus.COMPLETED }));

		dispatch(updateUser({ telephone: data.mobile, givenNames: data.givenNames, lastNames: data.lastNames }));

		navigate('/dashboard');
	};

	return (
		<FormProvider {...form}>
			<Page title='Login'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<BrandCard title='My contact details are...'>
							<TextInput name='givenNames' placeholder='Enter your first and middle names ' />
							<TextInput name='lastNames' placeholder='Enter your last name(s)' />
							{!user.isPrincipal && (
								<>
									<TextInput name='email' placeholder='Enter your work email' disabled />
									<TextInput name='mobile' placeholder='Enter your work mobile' />
								</>
							)}
							<ContinueButton variant='contained' fullWidth type='submit' loading={form.formState.isSubmitting}>
								Continue
							</ContinueButton>
							<OnboardingFooter />
						</BrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default OnboardingInfo;
