import { LoadingButton } from '@mui/lab';
import { Box, Card, Autocomplete as MuiAutocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BrandCard } from 'src/components';
import BrandPage from 'src/components/common/BrandPage';
import GoogleAddressAutocomplete from 'src/components/GoogleAddressAutoComplete';
import { RHFAutocomplete, RHFPhoneNumberInput, RHFTextField } from 'src/components/hook-form';

// ---------------------------------
// Login

export const LoginRootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex',
		flexDirection: 'column',
	},
}));

export const LoginHeaderStyle = styled('header')(({ theme }) => ({
	top: 0,
	zIndex: 9,
	lineHeight: 0,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	position: 'absolute',
	padding: theme.spacing(3),
	justifyContent: 'space-between',
	[theme.breakpoints.up('md')]: {
		alignItems: 'flex-end',
		padding: theme.spacing(7, 5, 0, 7),
	},
}));

export const LoginSectionStyle = styled(Card)(({ theme }) => ({
	width: '100%',
	maxWidth: 464,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	margin: theme.spacing(2, 0, 2, 0),
	boxShadow: 'none',
}));

export const LoginContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
// NewPassword

export const NewPasswordContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
// Register

export const RegisterRootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex',
	},
}));

export const RegisterHeaderStyle = styled('header')(({ theme }) => ({
	top: 0,
	zIndex: 9,
	lineHeight: 0,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	position: 'absolute',
	padding: theme.spacing(3),
	justifyContent: 'space-between',
	[theme.breakpoints.up('md')]: {
		alignItems: 'flex-start',
		padding: theme.spacing(7, 5, 0, 7),
	},
}));

export const RegisterSectionStyle = styled('div')(({ theme }) => ({
	width: '100%',
	maxWidth: 464,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'top',
	margin: theme.spacing(2, 0, 2, 2),
	marginTop: '60px',
}));

export const RegisterContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 657,
	margin: 'auto',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
// ResetPassword

export const ResetPasswordContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
// VerifyCode

export const VerifyContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// TempPassModal

export const TitleStyle = {
	marginBottom: '16px',
	fontWeight: 700,
	fontSize: '18px',
	lineHeight: '20px',
};

export const InputStyle = {
	width: '400px',
	marginBottom: '40px',
};

export const BtnStyle = {
	height: '56px',
	width: '100%',
	fontSize: 16,
};

export const Separator = styled(Box)(({ theme }) => ({
	textAlign: 'center',
	height: '1px',
	width: '75%',
	background: theme.palette.grey[300],
}));

export const TextInput = styled(RHFTextField)({
	'&&& .MuiInputBase-root': {
		borderRadius: '2px',
		outline: 'none',
		input: {
			fontSize: '13px',
			outline: 'none',
			padding: '9px 12px',
		},
	},
});

export const PhoneTextInput = styled(RHFPhoneNumberInput)({
	'&&& .MuiInputBase-root': {
		borderRadius: '2px',
		outline: 'none',
		input: {
			fontSize: '13px',
			outline: 'none',
			padding: '9px 12px',
		},
	},
});

export const StyledGoogleAddressAutocomplete = styled(GoogleAddressAutocomplete)({
	borderRadius: '2px !important',
	'.MuiInputBase-root': { borderRadius: '2px !important', padding: '5px 10px !important' },
	'.MuiInputBase-input': { fontSize: '13px !important', padding: '5px !important' },
	'.MuiFormLabel-root': { fontSize: '13px !important', top: '-3px !important' },
});

export const ContinueButton = styled(LoadingButton)({
	fontSize: '14px',
	fontWeight: 600,
	borderRadius: '2px',
	textTransform: 'initial',
});

export const AuthPage = styled(BrandPage)({
	backgroundImage: `url(/assets/geometric_background.jpg)`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
});

export const CompanyBrandCard = styled(BrandCard)({
	'.MuiPaper-root': { fontSize: '13px', borderRadius: '2px' },
	'.MuiAutocomplete-option': { borderRadius: '2px !important' },
});

export const Autocomplete = styled(RHFAutocomplete)({
	borderRadius: '2px !important',
	'.MuiInputBase-root': { borderRadius: '2px', padding: '5px 10px' },
	'.MuiInputBase-input': { fontSize: '13px', padding: '5px' },
	'.MuiFormLabel-root': { fontSize: '13px', top: '-3px' },
});

export const StyledMuiAutocomplete = styled(MuiAutocomplete)({
	borderRadius: '2px !important',
	'.MuiInputBase-root': { borderRadius: '2px !important', padding: '5px 10px !important' },
	'.MuiInputBase-input': { fontSize: '13px !important', padding: '5px !important' },
	'.MuiFormLabel-root': { fontSize: '13px !important', top: '-3px !important' },
});
