import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { dispatch } from 'src/redux/store';
import { selectUser, updateUser, updateUserOnboarding } from 'src/redux/slices/auth';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import useFirms from 'src/hooks/useFirms';
import { getFirmRef } from 'src/utils/firebase';
import { useNavigate } from 'react-router';
import { AuthPage, Autocomplete, CompanyBrandCard, ContinueButton } from '../styles';
import { OnboardingCompanyFormType } from './types';
import { onboardingCompanyValidationSchema } from './validation';
import OnboardingFooter from '../OnboardingFooter';

const OnboardingCompany = () => {
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const { areFirmsLoading, firms, fetchFirms } = useFirms();
	const form = useForm<OnboardingCompanyFormType>({
		resolver: yupResolver(onboardingCompanyValidationSchema),
		defaultValues: { business: user.business?.id },
	});

	const handleSubmit = async (data: OnboardingCompanyFormType) => {
		await Promise.all([
			dispatch(updateUserOnboarding({ company: true, branch: false })),
			dispatch(updateUser({ business: getFirmRef(data.business) })),
		]);
	};

	useEffect(() => {
		fetchFirms(user.isEstateAgent);
	}, [user.professions]);

	const handleCantFindFirm = async () => {
		await dispatch(updateUserOnboarding({ company: true, newCompany: false }));
		navigate('/onboarding/new-company');
	};

	return (
		<FormProvider {...form}>
			<Page title='Login'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<CompanyBrandCard title='I work at...'>
							<Autocomplete
								name='business'
								label='Firm'
								options={firms}
								loading={areFirmsLoading}
								labelAttribute='tradingName'
							/>
							<ContinueButton variant='contained' fullWidth type='submit' loading={form.formState.isSubmitting}>
								Continue
							</ContinueButton>
							<OnboardingFooter withFirmNotFound handleFirmNotFoundClick={handleCantFindFirm} />
						</CompanyBrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default OnboardingCompany;
