import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { dispatch } from 'src/redux/store';
import {
	addCompanyThunk,
	goBackOnUserOnboarding,
	searchCompanyThunk,
	updateUser,
	updateUserOnboarding,
} from 'src/redux/slices/auth';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import { getFirmRef } from 'src/utils/firebase';
import { AuthPage, CompanyBrandCard, ContinueButton, TextInput } from '../styles';
import { OnboardingAddCompanyType, OnboardingSearchCompanyType } from './types';
import { onboardingAddCompanyValidationSchema, onboardingSearchCompanyValidationSchema } from './validation';
import OnboardingFooter from '../OnboardingFooter';

const OnboardingNewCompany = () => {
	const navigate = useNavigate();
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const searchCompanyForm = useForm<OnboardingSearchCompanyType>({
		resolver: yupResolver(onboardingSearchCompanyValidationSchema),
	});
	const addCompanyForm = useForm<OnboardingAddCompanyType>({
		resolver: yupResolver(onboardingAddCompanyValidationSchema),
		defaultValues: { company: null },
	});
	const foundCompany = addCompanyForm.watch('company');

	const handleSearch = async (data: OnboardingSearchCompanyType) => {
		try {
			setIsLoading(true);

			const company = await dispatch(searchCompanyThunk(data)).unwrap();

			addCompanyForm.reset({ company });
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) searchCompanyForm.setError('regNum', { message: e.message });
		} finally {
			setIsLoading(false);
		}
	};
	const handleAdd = async (data: OnboardingAddCompanyType) => {
		try {
			if (!data.company) return;

			setIsLoading(true);

			const newCompany = await dispatch(addCompanyThunk(data.company)).unwrap();

			await dispatch(updateUserOnboarding({ newCompany: true, newBranch: false }));
			await dispatch(updateUser({ business: getFirmRef(newCompany.id) }));
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};
	const handleVerify = async () => {
		try {
			if (!foundCompany?.id) return;

			setIsLoading(true);

			await dispatch(updateUserOnboarding({ newCompany: true, branch: false }));
			await dispatch(updateUser({ business: getFirmRef(foundCompany.id) }));
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};
	const handleCancelSearch = async () => {
		await dispatch(goBackOnUserOnboarding());
		navigate('/onboarding/select-company');
	};
	const handleCancelAdd = async () => addCompanyForm.reset({ company: null });

	return (
		<Page title='Login'>
			<AuthPage>
				{foundCompany ? (
					foundCompany.isFromHousingApi ? (
						<FormProvider {...addCompanyForm}>
							<form onSubmit={addCompanyForm.handleSubmit(handleAdd)}>
								<CompanyBrandCard title='Add new company'>
									<TextInput
										name='company.legalName'
										placeholder='Company legal name'
										disabled
										value={addCompanyForm.watch('company.legalName')}
									/>
									<TextInput name='company.regNum' placeholder='Company registration number' disabled />
									<TextInput name='company.tradingName' placeholder='Company trading name' />
									<Box display='flex' gap='10px' width='100%'>
										<ContinueButton variant='outlined' fullWidth loading={isLoading} onClick={handleCancelAdd}>
											Cancel
										</ContinueButton>
										<ContinueButton variant='contained' fullWidth type='submit' loading={isLoading}>
											Submit
										</ContinueButton>
									</Box>
									<OnboardingFooter />
								</CompanyBrandCard>
							</form>
						</FormProvider>
					) : (
						<FormProvider {...addCompanyForm}>
							<form onSubmit={addCompanyForm.handleSubmit(handleVerify)}>
								<CompanyBrandCard title='Verify company'>
									<TextInput
										name='company.legalName'
										placeholder='Company legal name'
										disabled
										value={addCompanyForm.watch('company.legalName')}
									/>
									<TextInput name='company.tradingName' placeholder='Company trading name' disabled />
									<TextInput name='company.regNum' placeholder='Company registration number' disabled />
									<Box display='flex' gap='10px' width='100%'>
										<ContinueButton variant='outlined' fullWidth loading={isLoading} onClick={handleCancelAdd}>
											Cancel
										</ContinueButton>
										<ContinueButton variant='contained' fullWidth loading={isLoading} type='submit'>
											Continue
										</ContinueButton>
									</Box>
									<OnboardingFooter />
								</CompanyBrandCard>
							</form>
						</FormProvider>
					)
				) : (
					<FormProvider {...searchCompanyForm}>
						<form onSubmit={searchCompanyForm.handleSubmit(handleSearch)}>
							<CompanyBrandCard title='Find company'>
								<TextInput name='regNum' placeholder='Enter company registration number' />
								<Box display='flex' gap='10px' width='100%'>
									<ContinueButton variant='outlined' fullWidth loading={isLoading} onClick={handleCancelSearch}>
										Cancel
									</ContinueButton>
									<ContinueButton variant='contained' fullWidth type='submit' loading={isLoading}>
										Continue
									</ContinueButton>
								</Box>
								<OnboardingFooter />
							</CompanyBrandCard>
						</form>
					</FormProvider>
				)}
			</AuthPage>
		</Page>
	);
};

export default OnboardingNewCompany;
