import { ReactNode } from 'react';
import { dynamicTaskType, taskStatus, taskType } from 'src/constants/tasks';
import { Task, TaskType, TaskUser } from 'src/redux/types';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
import {
	acceptTransactionInvitation,
	getTransactionOverviewThunk,
	getTransactionParticipantsThunk,
	processTransactionOnboarding,
	rejectTransactionInvitation,
	removeTask,
	selectTransactionOverview,
	setCurrentTransactionTab,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { openModal } from 'src/redux/slices/modal';
import { findTransactionOnboarding } from 'src/utils/firebase/transaction-onboarding';
import syncTransactionOnboarding from 'src/api/transaction-onboarding/sync';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Typography } from '@mui/material';
import modals from 'src/constants/modals';
import moment from 'moment';
import { getTaskRef } from 'src/utils/firebase';
import { updateDoc } from 'firebase/firestore';
import LinkButton from '../../LinkButton';
import UploadButton from '../../UploadButton';
import SignButton from '../../SignButton';

export const useTask = (task: Task, user?: TaskUser): { statement: ReactNode; description?: ReactNode } => {
	const navigate = useNavigate();
	const currentUser = useSelector(selectUser);
	const transaction = useSelector(selectTransactionOverview);

	const isCurrentUserTask = user?.id === currentUser.uid;
	const disabled = !isCurrentUserTask || !user?.isApproved;
	const uploadButtonDisabled = disabled || !user?.isVerified;

	const handleGoToQuestionnaires = () => dispatch(setCurrentTransactionTab('questions'));
	const handleGoToParticipants = () => dispatch(setCurrentTransactionTab('participants'));
	const handleEnterPersonalDetails = () => {
		dispatch(
			openModal({
				name: QuestionnaireTransactionEnum.personal_details,
				payload: { userId: currentUser.uid, taskId: task.id },
			}),
		);
	};
	const handleGetVerified = async () => {
		const onboardingSnapshot = await findTransactionOnboarding(currentUser.uid, transaction.id);

		if (!onboardingSnapshot) await syncTransactionOnboarding(transaction.id, 'verification');

		dispatch(processTransactionOnboarding({ transactionId: transaction.id }));
		dispatch(removeTask({ taskId: task.id, userId: currentUser.uid }));
	};

	const handleGoToBankAccounts = () => navigate('/account-settings/bank-accounts');
	const handleReviewBankAccounts = async () => {
		await updateDoc(getTaskRef(task.id), { status: taskStatus.done });
		dispatch(removeTask({ taskId: task.id, userId: currentUser.uid }));
		handleGoToBankAccounts();
	};

	const handleAccept = async () => {
		await dispatch(
			acceptTransactionInvitation({
				role: task.metadata?.role,
				side: task.metadata?.side,
				transactionId: transaction.id,
				userId: currentUser.uid,
			}),
		);
		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
		dispatch(removeTask({ taskId: task.id, userId: currentUser.uid }));
	};
	const handleReject = async () => {
		await dispatch(
			rejectTransactionInvitation({
				role: task.metadata?.role,
				side: task.metadata?.side,
				transactionId: transaction.id,
				userId: currentUser.uid,
			}),
		);

		navigate(PATH_DASHBOARD.root);
	};

	const handleAssignTitle = () => dispatch(openModal({ name: modals.assignTitle }));

	if (!task.type) return { description: null, statement: null };

	if (task.type.startsWith(dynamicTaskType.uploadSellBankStatements)) {
		return {
			description: (
				<>
					<b>SELL | Documents</b> | Bank statements for the account ending in{' '}
					{task.metadata?.account ?? task.metadata?.accountNumber}`
				</>
			),
			statement: (
				<>
					<b>Anti-fraud checks (Account ending in {task.metadata?.account ?? task.metadata?.accountNumber})</b>: Please
					upload the past 3 months' bank statements and one from at least 12 months ago.{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
		};
	}

	if (task.type.startsWith(dynamicTaskType.uploadSellMortgageStatement)) {
		return {
			description: (
				<>
					<b>SELL | Documents</b> | Mortgage statement for account ending in {task.metadata?.accountNumber}`
				</>
			),
			statement: (
				<>
					<b>Mortgage redemption</b>: Please upload the latest annual statement for the mortgage account ending in{' '}
					{task.metadata?.accountNumber} <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
		};
	}

	if (task.type.startsWith(dynamicTaskType.uploadBuyBankStatements)) {
		return {
			description: (
				<>
					<b>BUY | Documents</b> | Bank statements for the account ending in{' '}
					{task.metadata?.account ?? task.metadata?.accountNumber}`
				</>
			),
			statement: (
				<>
					<b>Anti-fraud checks (Account ending in {task.metadata?.account ?? task.metadata?.accountNumber})</b>: Please
					upload the past 3 months' bank statements and one from at least 12 months ago.{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
		};
	}

	const paramsByType: Record<TaskType, { statement: ReactNode; description?: ReactNode }> = {
		[taskType.acceptInvite]: {
			statement: (
				<>
					Please{' '}
					<LinkButton handleClick={handleAccept} disabled={!isCurrentUserTask} task={task} shouldRemoveTask={false}>
						[Accept]
					</LinkButton>{' '}
					or{' '}
					<LinkButton handleClick={handleReject} disabled={!isCurrentUserTask} task={task} shouldRemoveTask={false}>
						[Reject]
					</LinkButton>{' '}
					your invitation
				</>
			),
			description: (
				<>
					<b>{task.metadata?.side?.toUpperCase()} | Invite</b> | {task.metadata?.givenNames} {task.metadata?.lastNames}{' '}
				</>
			),
		},
		[taskType.getVerified]: {
			statement: (
				<>
					Please complete your ID checks{' '}
					<LinkButton handleClick={handleGetVerified} disabled={disabled} task={task} shouldRemoveTask={false}>
						[Go]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>{task.metadata?.side?.toUpperCase()} | ID check </b> | {task.metadata?.givenNames}{' '}
					{task.metadata?.lastNames}
				</>
			),
		},
		[taskType.confirmVerificationResults]: {
			statement: (
				<>
					Please confirm your ID check results{' '}
					<LinkButton handleClick={handleGoToParticipants} disabled={disabled} task={task}>
						[Go]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>{task.metadata?.side?.toUpperCase()} | ID results</b> | {task.metadata?.givenNames}{' '}
					{task.metadata?.lastNames}
				</>
			),
		},
		[taskType.enterPersonalDetails]: {
			statement: (
				<>
					Please enter your personal details{' '}
					<LinkButton handleClick={handleEnterPersonalDetails} disabled={disabled} task={task} shouldRemoveTask={false}>
						[Go]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>{task.metadata?.side?.toUpperCase()} | Details</b> | {task.metadata?.givenNames} {task.metadata?.lastNames}
				</>
			),
		},
		[taskType.completeSellQuestions]: {
			statement: (
				<>
					Please fill out the seller questions{' '}
					<LinkButton handleClick={handleGoToQuestionnaires} disabled={disabled} task={task}>
						[Go]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>SELL | Questions</b> | Answered
				</>
			),
		},
		[taskType.completePropertyQuestions]: {
			statement: (
				<>
					Please fill out the property questions{' '}
					<LinkButton handleClick={handleGoToQuestionnaires} disabled={disabled} task={task}>
						[Go]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>PROPERTY | Questions</b> | Answered
				</>
			),
		},
		[taskType.createUserBankAccounts]: {
			statement: (
				<>
					<b>Anti-fraud checks</b>: Please provide details of all bank accounts used in this transaction{' '}
					<LinkButton handleClick={handleGoToBankAccounts} disabled={disabled} task={task}>
						[Go]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>{task.metadata?.side?.toUpperCase()} | Documents</b> | Bank accounts added
				</>
			),
		},
		[taskType.reviewUserBankAccounts]: {
			statement: (
				<>
					<b>Anti-fraud checks</b>: Please review your bank accounts. List all accounts required for this transaction{' '}
					<LinkButton handleClick={handleReviewBankAccounts} disabled={disabled} task={task}>
						[Review]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>{task.metadata?.side?.toUpperCase()} | Documents</b> | Bank accounts added
				</>
			),
		},
		[taskType.uploadUserBankAccountStatements]: {
			statement: (
				<>
					<b>
						Anti-fraud checks ({task.metadata?.bank} account with {task.metadata?.displayField} ending in{' '}
						{task.metadata?.displayValue}
					</b>
					): Please upload the past 3 months' bank statements and one from at least 12 months.{' '}
					<LinkButton handleClick={handleGoToBankAccounts} disabled={disabled} task={task}>
						[Upload]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>{task.metadata?.side?.toUpperCase()} | Documents</b> | Bank statements for {task.metadata?.label}
				</>
			),
		},
		[taskType.completeBuyQuestions]: {
			statement: (
				<>
					Please fill out the buyer questions{' '}
					<LinkButton handleClick={handleGoToQuestionnaires} disabled={disabled} task={task}>
						[Go]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>BUY | Questions</b> | Answered
				</>
			),
		},
		[taskType.completeGiftQuestions]: {
			statement: (
				<>
					Please fill out your gift questionnaire for Gift {task.metadata?.number}{' '}
					<LinkButton handleClick={handleGoToQuestionnaires} disabled={disabled} task={task}>
						[Go]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>BUY | Questions</b> | Gift {task.metadata?.number} answered
				</>
			),
		},
		[taskType.uploadGiftProofOfFunds]: {
			statement: (
				<>
					<b>Proof of funds (Gift {task.metadata?.number})</b>: Please upload evidence showing the receipt of the gift
					into your bank account ending in {task.metadata?.account}{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | {task.metadata?.number}
				</>
			),
		},
		[taskType.uploadMemorandumOfSale]: {
			statement: (
				<>
					Please upload the Memorandum of Sale <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>LISTING</b> | Memorandum of Sale
				</>
			),
		},
		[taskType.uploadMemorandumOfPurchase]: {
			statement: (
				<>
					Please upload the Memorandum of Purchase <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>LISTING</b> | Memorandum of Sale
				</>
			),
		},
		[taskType.uploadFloorPlan]: {
			statement: (
				<>
					Please upload the property's floor plan <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Floor plan
				</>
			),
		},
		[taskType.uploadProtectedBuilding]: {
			statement: (
				<>
					Please upload the Building Preservation Notice <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Building Preservation Notice
				</>
			),
		},
		[taskType.uploadProtectedTrees]: {
			statement: (
				<>
					Please upload the Tree Preservation Order <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Tree Preservation Notice
				</>
			),
		},
		[taskType.uploadAlterationsUse]: {
			statement: (
				<>
					<b>Building works</b>: Please upload any planning permissions, Building Regulations approvals, and Completion
					Certificates for the property's change of use <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Change of use
				</>
			),
		},
		[taskType.uploadAlterationsWindows]: {
			statement: (
				<>
					<b>Building works</b>: Please upload any planning permissions, Building Regulations approvals, and Completion
					Certificates for the installation of replacement windows, roof windows, roof lights, or glazed doors{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Windows/Lights/Glazed doors
				</>
			),
		},
		[taskType.uploadAlterationsExtension]: {
			statement: (
				<>
					<b>Building works</b>: Please upload any planning permissions, Building Regulations approvals, and Completion
					Certificates for the extension addition <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Extension
				</>
			),
		},
		[taskType.uploadAlterationsConservatory]: {
			statement: (
				<>
					<b>Building works</b>: Please upload any planning permissions, Building Regulations approvals, and Completion
					Certificates for the conservatory addition <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Conservatory
				</>
			),
		},
		[taskType.uploadLoftConversion]: {
			statement: (
				<>
					<b>Building works</b>: Please upload any planning permissions, Building Regulations approvals, and Completion
					Certificates for the loft conversion <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Loft conversion
				</>
			),
		},
		[taskType.uploadGarageConversion]: {
			statement: (
				<>
					<b>Building works</b>: Please upload any planning permissions, Building Regulations approvals, and Completion
					Certificates for the garage conversion <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Garage conversion
				</>
			),
		},
		[taskType.uploadInternalWalls]: {
			statement: (
				<>
					<b>Building works</b>: Please upload any planning permissions, Building Regulations approvals, and Completion
					Certificates for the removal of internal walls <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Removal of internal walls
				</>
			),
		},
		[taskType.uploadOtherWorks]: {
			statement: (
				<>
					<b>Building works</b>: Please upload any planning permissions, Building Regulations approvals, and Completion
					Certificates for your other building works <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Other building works
				</>
			),
		},
		[taskType.uploadHomeWarranty]: {
			statement: (
				<>
					<b>Warranties</b>: Please upload your new home warranty{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | New home warranty
				</>
			),
		},
		[taskType.uploadUnderpinningWarranty]: {
			statement: (
				<>
					<b>Warranties</b>: Please upload your underpinning warranty{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Underpinning warranty
				</>
			),
		},
		[taskType.uploadCentralHeatingWarranty]: {
			statement: (
				<>
					<b>Warranties</b>: Please upload your central heating warranty{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Central heating warranty
				</>
			),
		},
		[taskType.uploadRoofingWarranty]: {
			statement: (
				<>
					<b>Warranties</b>: Please upload your roofing warranty{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Roofing warranty
				</>
			),
		},
		[taskType.uploadElectricalWorkWarranty]: {
			statement: (
				<>
					<b>Warranties</b>: Please upload your electrical work warranty{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Electrical work warranty
				</>
			),
		},
		[taskType.uploadWindowsWarranty]: {
			statement: (
				<>
					<b>Warranties</b>: Please upload your windows warranty{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Windows warranty
				</>
			),
		},
		[taskType.uploadTimberTreatmentWarranty]: {
			statement: (
				<>
					<b>Warranties</b>: Please upload your timber treatment warranty{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Timber treatment warranty
				</>
			),
		},
		[taskType.uploadDampRoofingWarranty]: {
			statement: (
				<>
					<b>Warranties</b>: Please upload your damp roofing warranty{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Damp roofing warranty
				</>
			),
		},
		[taskType.uploadOtherWarranties]: {
			statement: (
				<>
					<b>Warranties</b>: Please upload any other guarantees and warranties{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Other guarantees / warranties
				</>
			),
		},
		[taskType.uploadBuildingInsurance]: {
			statement: (
				<>
					<b>Insurance</b>: Please upload your buildings insurance policy{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Buildings insurance policy
				</>
			),
		},
		[taskType.uploadHomeInsurance]: {
			statement: (
				<>
					<b>Insurance</b>: Please upload your home insurance policy{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Home insurance policy
				</>
			),
		},
		[taskType.uploadJapaneseKnotweed]: {
			statement: (
				<>
					<b>Insurance</b>: Please upload your Japanese knotweed management plan{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Japanese knotweed management plan
				</>
			),
		},
		[taskType.orderManagementPack]: {
			statement: (
				<>
					<b>Management</b>: Please contact your management company to obtain a management pack{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Management Pack
				</>
			),
		},
		[taskType.uploadGroundRent]: {
			statement: (
				<>
					<b>Management</b>: Please upload ground rent invoices for the last 3 years{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Ground rent invoices
				</>
			),
		},
		[taskType.uploadServiceCharge]: {
			statement: (
				<>
					<b>Management</b>: Please upload service charge invoices for the last 3 years{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Service charge invoices
				</>
			),
		},
		[taskType.uploadFreeholdManagementCompany]: {
			statement: (
				<>
					<b>Management</b>: Please upload the Freehold Management Company's memorandum and articles, share certificate,
					and latest accounts <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Freehold management company
				</>
			),
		},
		[taskType.uploadSewerage]: {
			statement: (
				<>
					<b>Services</b>: Please upload plans showing sewerage location and access{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Sewerage location
				</>
			),
		},
		[taskType.uploadPartyWall]: {
			statement: (
				<>
					<b>Neighbours</b>: Please upload any party wall notices you have received{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Party wall notices (received)
				</>
			),
		},
		[taskType.uploadEICR]: {
			statement: (
				<>
					<b>Safety</b>: Please upload the Electrical Installation Condition Report (EICR){' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | EICR
				</>
			),
		},
		[taskType.uploadGasSafeRegister]: {
			statement: (
				<>
					<b>Safety</b>: Please upload the Gas Safe Register{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Gas Safe Register
				</>
			),
		},
		[taskType.uploadNoticeOfSale]: {
			statement: (
				<>
					<b>Management</b>: Please upload any notice from the landlord regarding the sale of the freehold{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Freehold sale (received)
				</>
			),
		},
		[taskType.uploadNoticeAboutBuilding]: {
			statement: (
				<>
					<b>Management</b>: Please upload any notices related to the building, its use, condition, or repair{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Building (received)
				</>
			),
		},
		[taskType.uploadLeaseTermChanges]: {
			statement: (
				<>
					<b>Lease</b>: Please upload a copy of the lease term changes or any evidence of landlord consent under the
					lease terms <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Lease term changes / consents granted
				</>
			),
		},
		[taskType.uploadLandlordResponseToNotice]: {
			statement: (
				<>
					<b>Freehold</b>: Please upload the landlord's response to the notice{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
		},
		[taskType.uploadBuyMortgage]: {
			statement: (
				<>
					<b>Source of funds (Mortgage)</b>: Please upload your mortgage offer / offer in principle{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | Mortgage
				</>
			),
		},
		[taskType.uploadBuyRemortgage]: {
			statement: (
				<>
					<b>Source of funds (Remortgage)</b>: Please upload your remortgage offer / offer in principle{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | Remortgage
				</>
			),
		},
		[taskType.uploadBuyEquityRelease]: {
			statement: (
				<>
					<b>Source of funds (Equity release)</b>: Please upload your home reversion plan offer or lifetime mortgage
					offer <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | Equity release
				</>
			),
		},
		[taskType.uploadBuySavings]: {
			statement: (
				<>
					<b>Source of funds (Savings)</b>: Please upload bank statements for the last 3 months{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | Savings
				</>
			),
		},
		[taskType.uploadBuyInheritance]: {
			statement: (
				<>
					<b>Source of funds (Inheritance)</b>: Please upload evidence from the executors of the estate{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | Inheritance
				</>
			),
		},
		[taskType.uploadBuyPropertySale]: {
			statement: (
				<>
					<b>Source of funds (Property sale)</b>: Please upload a copy of the completion statement (if sold) or client
					care letter (if on sale) <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | Property sale
				</>
			),
		},
		[taskType.uploadBuyPension]: {
			statement: (
				<>
					<b>Source of funds (Pension)</b>: Please upload your latest pension statement{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | Pension
				</>
			),
		},
		[taskType.uploadBuyGambling]: {
			statement: (
				<>
					<b>Source of funds (Gambling)</b>: Please upload a receipt proving your winnings{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | Winnings
				</>
			),
		},
		[taskType.uploadBuyCompensation]: {
			statement: (
				<>
					<b>Source of funds (Compensation)</b>: Please upload an official letter confirming your compensation
					settlement <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF </b> | Compensation
				</>
			),
		},
		[taskType.titlePendingApplications]: {
			statement: <>Request a new copy of the title once all pending applications are completed.</>,
		},
		[taskType.assignTitle]: {
			statement: (
				<>
					Please assign a title number to this property{' '}
					<LinkButton handleClick={handleAssignTitle} disabled={!isCurrentUserTask} task={task}>
						[Assign]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>PROPERTY | Onboarding</b> | Title
				</>
			),
		},
		[taskType.obtainNewEPC]: {
			statement: <>Please obtain a new Energy Performance Certificate (EPC)</>,
			description: (
				<>
					<b>SELL | Documents </b> | EPC
				</>
			),
		},
		[taskType.uploadTenantsNotice]: {
			statement: (
				<>
					<b>Freehold purchase</b>: Please upload the tenant's notice to the landlord{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Freehold purchase (Tenant's notice)
				</>
			),
		},
		[taskType.uploadCollectivePurchaseNotice]: {
			statement: (
				<>
					<b>Freehold purchase</b>: Please upload the collective purchase notice to the landlord{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Freehold purchase (Collective notice)
				</>
			),
		},
		[taskType.uploadSection42Notice]: {
			statement: (
				<>
					<b>Lease extension</b>: Please upload the Section 42 notice served to the landlord{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Lease extension (Section 42 notice)
				</>
			),
		},
		[taskType.uploadTenantsResponse]: {
			statement: (
				<>
					<b>Freehold purchase</b>: Please upload the landlord's response to the tenant's notice{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Freehold purchase (Landlord's response)
				</>
			),
		},
		[taskType.uploadCollectivePurchaseResponse]: {
			statement: (
				<>
					<b>Freehold purchase</b>: Please upload the landlord's response to the collective purchase notice{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Freehold purchase (Landlord's response)
				</>
			),
		},
		[taskType.uploadSection42Response]: {
			statement: (
				<>
					<b>Lease extension</b>: Please upload the landlord's response to the Section 42 notice{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents </b> | Lease extension (Landlord's response)
				</>
			),
		},
		[taskType.uploadGiftLoan]: {
			statement: (
				<>
					<b>Source of funds (Loan)</b>: Please upload your loan agreement{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF | {task.metadata?.number} (Loan) </b>
				</>
			),
		},
		[taskType.uploadGiftRemortgage]: {
			statement: (
				<>
					<b>Source of funds (Remortgage)</b>: Please upload your remortgage offer / offer in principle{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF | {task.metadata?.number} (Remortgage) </b>
				</>
			),
		},
		[taskType.uploadGiftEquityRelease]: {
			statement: (
				<>
					<b>Source of funds (Equity release)</b>: Please upload your home reversion plan offer or lifetime mortgage
					offer <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF | {task.metadata?.number} (Equity release) </b>
				</>
			),
		},
		[taskType.uploadGiftSavings]: {
			statement: (
				<>
					<b>Source of funds (Savings)</b>: Please upload bank statements for the last 3 months{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF | {task.metadata?.number} (Savings) </b>
				</>
			),
		},
		[taskType.uploadGiftInheritance]: {
			statement: (
				<>
					<b>Source of funds (Inheritance)</b>: Please upload evidence from the executors of the estate{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF | {task.metadata?.number} (Inheritance) </b>
				</>
			),
		},
		[taskType.uploadGiftPropertySale]: {
			statement: (
				<>
					<b>Source of funds (Property Sale)</b>: Please upload a copy of the completion statement (if sold) or client
					care letter (if on sale) <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF | {task.metadata?.number} (Property sale) </b>
				</>
			),
		},
		[taskType.uploadGiftPension]: {
			statement: (
				<>
					<b>Source of funds (Pension)</b>: Please upload your latest pension statement{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF | {task.metadata?.number} (Pension) </b>
				</>
			),
		},
		[taskType.uploadGiftGambling]: {
			statement: (
				<>
					<b>Source of funds (Winnings)</b>: Please upload a receipt proving your winnings{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF | {task.metadata?.number} (Winnings) </b>
				</>
			),
		},
		[taskType.uploadGiftCompensation]: {
			statement: (
				<>
					<b>Source of funds (Compensation)</b>: Please upload an official letter confirming your compensation
					settlement <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | SoF | {task.metadata?.number} (Compensation) </b>
				</>
			),
		},
		[taskType.uploadGiftBankStatements]: {
			statement: (
				<>
					<b>Anti-fraud checks (Account ending in {task.metadata?.account ?? task.metadata?.number})</b>: Please upload
					the past three months' bank statements and one from at least 12 months ago.{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>BUY | Documents</b> | Bank statements for account ending in{' '}
					{task.metadata?.account ?? task.metadata?.number}
				</>
			),
		},
		[taskType.uploadElectricalSafetyCertificate]: {
			statement: (
				<>
					<b>Electrical installation</b>: Please upload the BS7671 Electrical Safety Certificate{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents</b> | Electrical Safety Certificate
				</>
			),
		},
		[taskType.uploadElectricalBuildingCompliance]: {
			statement: (
				<>
					<b>Electrical installation</b>: Please upload the Building Regulations Compliance Certificate{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents</b> | Building Regulations Compliance Certificate
				</>
			),
		},
		[taskType.uploadElectricalBuildingCompletion]: {
			statement: (
				<>
					<b>Electrical installation</b>: Please upload the Building Control Completion Certificate{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents</b> | Building Control Completion Certificate
				</>
			),
		},
		[taskType.uploadElectricalInsurance]: {
			statement: (
				<>
					<b>Electrical installation</b>: Please take out and upload an insurance policy for the electrical installation
					works <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Solicitor</b> | Electrical installation insurance
				</>
			),
		},
		[taskType.orderBuySearches]: {
			statement: (
				<>
					<b>Searches</b>: Please complete your payment for the property searches{' '}
					<LinkButton
						handleClick={() => window.open(task.metadata?.url, '_blank')}
						disabled={!isCurrentUserTask}
						task={task}
					>
						[Pay]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>BUY | Searches</b> | Paid
				</>
			),
		},
		[taskType.orderSalePack]: {
			statement: (
				<>
					<b>Sale pack</b>: Please complete your payment{' '}
					<LinkButton
						handleClick={() => window.open(task.metadata?.url, '_blank')}
						disabled={!isCurrentUserTask}
						task={task}
					>
						[Pay]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>BUY | Sale Pack</b> | Paid
				</>
			),
		},
		[taskType.orderSellSearches]: {
			statement: (
				<>
					<b>Searches</b>: Ordering property searches now saves you up to 8 weeks{' '}
					<LinkButton
						handleClick={() => window.open(task.metadata?.url, '_blank')}
						disabled={!isCurrentUserTask}
						task={task}
					>
						[Order]
					</LinkButton>
				</>
			),
			description: (
				<>
					<b>SELL | Searches</b> | Paid
				</>
			),
		},
		[taskType.uploadNameChangeEvidence]: {
			statement: (
				<>
					<b>Anti-fraud checks</b>: Please upload proof of your name change from the one on the property title{' '}
					<UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents</b> | Name change ({task.metadata?.givenNames ?? '<empty>'}{' '}
					{task.metadata?.lastNames ?? '<empty>'})
				</>
			),
		},
		[taskType.uploadRadonTest]: {
			statement: (
				<>
					Please upload your property's radon test <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents</b> | Radon report
				</>
			),
		},
		[taskType.uploadFloodReport]: {
			statement: (
				<>
					Please upload your property's flood risk report <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents</b> | Flood report
				</>
			),
		},
		[taskType.uploadSolarPanels]: {
			statement: (
				<>
					<b>Services</b>: Please upload any paperwork about your solar panels, such as installation certificates and
					electricity bills for feed-in tariffs <UploadButton task={task} disabled={uploadButtonDisabled} />
				</>
			),
			description: (
				<>
					<b>SELL | Documents</b> | Solar panels documents
				</>
			),
		},
		[taskType.signSellerForms]: {
			statement: (
				<Typography color={uploadButtonDisabled ? 'inherit' : 'red'}>
					✎ Please review and sign the seller forms <SignButton task={task} disabled={uploadButtonDisabled} />
				</Typography>
			),
			description: (
				<>
					<b>SELL | Sign</b> | Seller Forms signed
				</>
			),
		},
		[taskType.signGiftDeclaration]: {
			statement: (
				<Typography color={uploadButtonDisabled ? 'inherit' : 'red'}>
					✎ Please review and sign the gifted deposit declaration for Gift {task.metadata?.number}
					<SignButton task={task} disabled={uploadButtonDisabled} />
				</Typography>
			),
			description: (
				<>
					<b>BUY | Gift Declaration</b>
				</>
			),
		},
		[taskType.viewSellerForms]: {
			statement: (
				<>
					Please review the seller forms on behalf of the signees.{' '}
					<SignButton task={task} disabled={uploadButtonDisabled} viewOnly />
				</>
			),
			description: (
				<>
					<b>SELL | View</b> | Seller Forms viewed
				</>
			),
		},
		[taskType.sellQuoteSent]: {
			statement: (
				<>
					<b>SELL | Quote</b> | Sent
				</>
			),
		},
		[taskType.sellQuoteAccepted]: {
			statement: (
				<>
					<b>SELL | Quote</b> | Accepted
				</>
			),
		},
		[taskType.searchesUploaded]: {
			statement: (
				<>
					<b>Searches</b> | Uploaded
				</>
			),
		},
		[taskType.searchesOrdered]: {
			statement: (
				<>
					<b>Searches</b> | Ordered
				</>
			),
		},
		[taskType.sellSolicitorAssigned]: {
			statement: (
				<>
					<b>SELL | Solicitor</b> | Assigned
				</>
			),
		},
		[taskType.sellCCLSent]: {
			statement: (
				<>
					<b>SELL | Solicitor</b> | CCL Sent
				</>
			),
		},
		[taskType.sellCCLSigned]: {
			statement: (
				<>
					<b>SELL | Solicitor</b> | CCL Signed
				</>
			),
		},
		[taskType.sellPropertyListed]: {
			statement: <>Property on the market</>,
		},
		[taskType.sellPriceSet]: {
			statement: <>Ask price set</>,
		},
		[taskType.sellOfferAccepted]: {
			statement: <>Sale agreed</>,
		},
		[taskType.buyQuoteSent]: {
			statement: (
				<>
					<b>BUY | Quote</b> | Sent
				</>
			),
		},
		[taskType.buyQuoteAccepted]: {
			statement: (
				<>
					<b>BUY | Quote</b> | Accepted
				</>
			),
		},
		[taskType.buySolicitorAssigned]: {
			statement: (
				<>
					<b>BUY | Solicitor</b> | Assigned
				</>
			),
		},
		[taskType.buyCCLSent]: {
			statement: (
				<>
					<b>BUY | Solicitor</b> | CCL Sent
				</>
			),
		},
		[taskType.buyCCLSigned]: {
			statement: (
				<>
					<b>BUY | Solicitor</b> | CCL Signed
				</>
			),
		},
		[taskType.sellContractSent]: {
			statement: (
				<>
					<b>SELL | Draft contract</b> | Sent
				</>
			),
		},
		[taskType.buyContractApproved]: {
			statement: (
				<>
					<b>BUY | Draft contract</b> | Approved
				</>
			),
		},
		[taskType.buyTR1Sent]: {
			statement: (
				<>
					<b>BUY | TR1</b> | Sent
				</>
			),
		},
		[taskType.sellTR1Approved]: {
			statement: (
				<>
					<b>SELL | TR1</b> | Approved
				</>
			),
		},
		[taskType.buyEnquiriesSent]: {
			statement: (
				<>
					<b>BUY | Enquiries</b> | Sent
				</>
			),
		},
		[taskType.sellEnquiriesReceived]: {
			statement: (
				<>
					<b>SELL | Enquiries</b> | Received
				</>
			),
		},
		[taskType.sellRepliesSent]: {
			statement: (
				<>
					<b>BUY | Enquiries</b> | Replies sent
				</>
			),
		},
		[taskType.buyRepliesReceived]: {
			statement: (
				<>
					<b>BUY | Enquiries</b> | Replies received
				</>
			),
		},
		[taskType.buyEnquiriesComplete]: {
			statement: (
				<>
					<b>BUY | Enquiries</b> | Completed
				</>
			),
		},
		[taskType.exchangeDateAgreed]: {
			statement: (
				<>
					Exchange date agreed
					{task.lastExchangeDueDate ? ` for ${moment(task.lastExchangeDueDate).format('DD/MM/YYYY')}` : ''}
				</>
			),
		},
		[taskType.completionDateAgreed]: {
			statement: (
				<>
					Completion date agreed
					{task.lastCompletionDueDate ? ` for ${moment(task.lastCompletionDueDate).format('DD/MM/YYYY')}` : ''}
				</>
			),
		},
		[taskType.hasExchanged]: {
			statement: (
				<>
					<b>Exchange</b>
				</>
			),
		},
		[taskType.hasCompleted]: {
			statement: (
				<>
					<b>Completion</b>
				</>
			),
		},
	};

	const params = paramsByType[task.type];

	if (params) {
		return {
			statement: (
				<>
					{task.snoozed ? <b>(Snoozed)</b> : ''} {params.statement}
				</>
			),
			description: params.description ? (
				<>
					{task.snoozed ? <b>(Snoozed)</b> : ''} {params.description}
				</>
			) : null,
		};
	}

	return { statement: null, description: null };
};
