import { TransactionOnboardingStep, TransactionRole, TransactionSide } from '../types';

export const transactionRoles = {
	buyer: {
		key: 'buyer',
		name: 'Buyer',
	},
	seller: {
		key: 'seller',
		name: 'Seller',
	},
	agent: {
		key: 'agent',
		name: 'Agent',
	},
	solicitor: {
		key: 'solicitor',
		name: 'Solicitor/Conveyancer',
	},
	mortgageBroker: {
		key: 'mortgageBroker',
		name: 'Mortgage Broker',
	},
	surveyor: {
		key: 'surveyor',
		name: 'Surveyor',
	},
	tradesPerson: {
		key: 'tradesPerson',
		name: 'Tradesperson',
	},
} as const;

export const transactionAgentRelationship = {
	salesProgressor: {
		key: 'salesProgressor',
		name: 'Sales progressor',
		shortName: 'Sales progressor',
	},
} as const;

export const transactionBuyerRelationship = {
	buyer: {
		key: 'buyer',
		name: 'Buyer',
		shortName: 'Buyer',
	},
	giftor: {
		key: 'giftor',
		name: 'Buyer (Giftor)',
		shortName: 'Giftor',
	},
	proxy: {
		key: 'proxy',
		name: 'Buyer (Helper)',
		shortName: 'Helper',
	},
	attorney: {
		key: 'attorney',
		name: 'Buyer (Attorney)',
		shortName: 'Attorney',
	},
} as const;

export const transactionSellerRelationship = {
	executor: {
		key: 'executor',
		name: 'Seller (Executor)',
		shortName: 'Executor',
	},
	attorney: {
		key: 'attorney',
		name: 'Seller (Attorney)',
		shortName: 'Attorney',
	},
	proprietor: {
		key: 'proprietor',
		name: 'Seller (Proprietor)',
		shortName: 'Proprietor',
	},
	proxy: {
		key: 'proxy',
		name: 'Seller (Helper)',
		shortName: 'Helper',
	},
	propertyDeveloper: {
		key: 'propertyDeveloper',
		name: 'Seller (Property Developer)',
		shortName: 'Property Developer',
	},
} as const;

export const transactionSolicitorRelationship = {
	solicitorConveyancer: {
		key: 'solicitorConveyancer',
		name: 'Solicitor/Conveyancer',
		shortName: 'Solicitor/Conveyancer',
	},
	paralegal: {
		key: 'paralegal',
		name: 'Paralegal',
		shortName: 'Paralegal',
	},
} as const;

export const transactionUserRelationship = {
	...transactionBuyerRelationship,
	...transactionSellerRelationship,
	...transactionSolicitorRelationship,
	...transactionAgentRelationship,
} as const;

export const transactionPartieSides = {
	sell: {
		key: 'sell',
		name: 'Sell Side',
	},
	buy: {
		key: 'buy',
		name: 'Buy Side',
	},
	view: {
		key: 'view',
		name: 'Viewers',
	},
} as const;

export const transactionQuoteRoles = [transactionRoles.buyer, transactionRoles.seller, transactionRoles.solicitor];

export const transactionRolesBySide: Record<TransactionSide, Array<{ key: TransactionRole; name: string }>> = {
	sell: [
		transactionRoles.seller,
		transactionRoles.agent,
		transactionRoles.mortgageBroker,
		transactionRoles.solicitor,
		transactionRoles.surveyor,
		transactionRoles.tradesPerson,
	],
	buy: [
		transactionRoles.buyer,
		transactionRoles.agent,
		transactionRoles.mortgageBroker,
		transactionRoles.solicitor,
		transactionRoles.surveyor,
		transactionRoles.tradesPerson,
	],
	view: [transactionRoles.buyer],
};

export const transactionRelationshipToTransactionRoles = {
	[transactionUserRelationship.proxy.key]: {
		[transactionPartieSides.sell.key]: transactionRoles.seller.key,
		[transactionPartieSides.buy.key]: transactionRoles.buyer.key,
		[transactionPartieSides.view.key]: transactionRoles.buyer.key,
	},
	[transactionUserRelationship.giftor.key]: {
		[transactionPartieSides.buy.key]: transactionRoles.buyer.key,
		[transactionPartieSides.view.key]: transactionRoles.buyer.key,
	},
	[transactionUserRelationship.paralegal.key]: {
		[transactionPartieSides.buy.key]: transactionRoles.solicitor.key,
		[transactionPartieSides.sell.key]: transactionRoles.solicitor.key,
	},
	[transactionUserRelationship.solicitorConveyancer.key]: {
		[transactionPartieSides.buy.key]: transactionRoles.solicitor.key,
		[transactionPartieSides.sell.key]: transactionRoles.solicitor.key,
	},
	[transactionUserRelationship.attorney.key]: {
		[transactionPartieSides.sell.key]: transactionRoles.seller.key,
	},
	[transactionUserRelationship.executor.key]: {
		[transactionPartieSides.sell.key]: transactionRoles.seller.key,
	},
	[transactionUserRelationship.proprietor.key]: {
		[transactionPartieSides.sell.key]: transactionRoles.seller.key,
	},
	[transactionUserRelationship.buyer.key]: {
		[transactionPartieSides.buy.key]: transactionRoles.buyer.key,
	},
};

export const extendedTransactionRoles = {
	viewer: {
		key: 'viewer',
		name: 'Viewer',
	},
	seller: {
		key: 'seller',
		name: 'Seller',
	},
	agent: {
		key: 'agent',
		name: 'Agent',
	},
	solicitor: {
		key: 'solicitor',
		name: 'Solicitor/Conveyancer',
	},
	mortgageBroker: {
		key: 'mortgageBroker',
		name: 'Mortgage Broker',
	},
	surveyor: {
		key: 'surveyor',
		name: 'Surveyor',
	},
	tradesPerson: {
		key: 'tradesPerson',
		name: 'Tradesperson',
	},
	...transactionUserRelationship,
} as const;

export const transactionDocuments = {
	cyberCrimeNotice: {
		key: 'CYBERCRIME_NOTICE',
		name: 'Cybercrime Notice',
	},
	deedsRequest: {
		key: 'DEEDS_REQUEST',
		name: 'Deeds Request (New Build)',
	},
	advanceEnquiries: {
		key: 'ADVANCE_ENQUIRIES',
		name: 'Advance Enquiries to Sellers',
	},
	CIFSellers: {
		key: 'CIF_SELLERS',
		task: 'SELLERS',
		name: 'Client Information Form (Sellers)',
	},
	ta6: {
		key: 'TA6',
		name: 'TA6 (Property)',
	},
	ta7: {
		key: 'TA7',
		name: 'TA7 (Leasehold)',
	},
	ta10: {
		key: 'TA10',
		name: 'TA10 (Contents)',
	},
	// 	buyer: {
	// 		key: 'BUYER',
	// 		name: 'Buyer Information Form',
	// 	},
	CIFBuyers: {
		key: 'CIF_BUYERS',
		task: 'BUYERS',
		name: 'Client Information Form (Buyers)',
	},
	SDLT: {
		key: 'SDLT',
		name: 'SDLT Form',
	},
	giftDeed: {
		key: 'GIFT_DEED',
		name: 'Gift Deed',
	},
	memorandumOfSale: {
		key: 'MEMORANDUM_OF_SALE',
		name: 'Memorandum of Sale',
	},
	contractPackSent: {
		key: 'CONTRACT_PACK_SENT',
		name: 'Confirmation of Instruction',
	},
	repliesToEnquiries: {
		key: 'REPLIES_TO_ENQUIRIES',
		name: 'Replies to Buyer Enquiries',
	},
	tr1: {
		key: 'TR1',
		name: 'TR1',
	},
	repliesToCompletionInfo: {
		key: 'REPLIES_TO_COMPLETION_INFO',
		name: 'Replies to Completion Information and Undertakings',
	},
	standardConditionsOfSale: {
		key: 'STANDARD_CONDITIONS_OF_SALE',
		name: 'Standard Conditions of Sale',
	},
	tr13: {
		key: 'TR13',
		name: 'TR13',
	},
	fileClosingNotice: {
		key: 'FILE_CLOSING_NOTICE',
		name: 'New Title Register and File Closing',
	},
	fix: {
		key: 'fix',
		name: 'Fix',
	},
} as const;

export const transactionRolesOptions = Object.values(transactionRoles);
export const transactionPartieSidesOptions = Object.values(transactionPartieSides);
export const defaultTransactionPartieSidesOptions = [transactionPartieSides.buy, transactionPartieSides.sell];
export const extendedTransactionRolesOptions = Object.values(extendedTransactionRoles);
export const transactionDocumentsOptions = Object.values(transactionDocuments);
export const transactionDocumentsByKeys = Object.values(transactionDocuments).reduce((acc, d) => {
	acc[d.key] = d;

	return acc;
}, {});
export const transactionUserRelationshipOptions = Object.values(transactionUserRelationship);
export const transactionSellerRelationshipOptions = Object.values(transactionSellerRelationship);
export const transactionBuyerRelationshipOptions = Object.values(transactionBuyerRelationship);
export const transactionRolesKeys = transactionRolesOptions.map(({ key }) => key);
export const transactionDocumentsKeys = transactionDocumentsOptions.map(({ key }) => key);
export const extendedTransactionRolesKeys = extendedTransactionRolesOptions.map(({ key }) => key);
export const transactionPartieSidesKeys = transactionPartieSidesOptions.map(({ key }) => key);
export const transactionUserRelationshipKeys = transactionUserRelationshipOptions.map(({ key }) => key);
export const transactionSellerRelationshipKeys = transactionSellerRelationshipOptions.map(({ key }) => key);
export const transactionBuyerRelationshipKeys = transactionBuyerRelationshipOptions.map(({ key }) => key);
export const transactionQuoteRolesKeys = transactionQuoteRoles.map(({ key }) => key);

export const transactionOnboardingSteps: Record<TransactionOnboardingStep, string> = {
	verificationIntroduction: 'transactionVerificationIntroduction',
	verification: 'transactionVerification',
	verificationInProgress: 'transactionVerificationInProgress',
	verificationFail: 'transactionVerificationFail',
	nameConfirmation: 'transactionNameConfirmation',
	nameIssue: 'transactionNameIssue',
	addressConfirmation: 'transactionAddressConfirmation',
	addressIssue: 'transactionAddressIssue',
	verificationIssue: 'transactionVerificationIssue',
	successfulVerification: 'transactionSuccessfulVerification',
	title: 'transactionTitle',
	titleHelp: 'transactionTitleHelp',
	titleError: 'transactionTitleError',
	titleConfirmation: 'transactionTitleConfirmation',
	completion: 'transactionCompletion',
} as const;

export const transactionOnboardingStepsKeys = Object.keys(transactionOnboardingSteps) as TransactionOnboardingStep[];
