import { getBranchAddressValidationSchema } from 'src/pages/dashboard/components/Companies/components/UpdateCompanyBranchModal/validation';
import { requiredString } from 'src/utils/schemas';
import * as Yup from 'yup';

export const onboardingNewBranchValidationSchema = Yup.object().shape({
	name: requiredString,
	address: getBranchAddressValidationSchema(false),
	telephone: requiredString,
	email: requiredString.email('Email must be valid email address'),
	website: requiredString.url('Must be valid website address'),
});
