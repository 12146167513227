import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Router from './routes';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import FullVersionModal from './components/FullVersionModal';
import {
	TermsAndConditionsModal,
	VerificationRequiredModal,
	PrivacyPolicyModal,
	AcceptableUsePolicyModal,
} from './components/modals';
import { endSession, logout as logoutApi, onUserDataChange, resumeSession } from './api';
import { dispatch } from './redux/store';
import { setIsInitialized, login, logout, selectIsAuthenticated, setIsBlocked } from './redux/slices/auth';
import { getNav } from './utils/propertyHelpers';
import { UserStatus } from './types';
import { getCountriesThunk } from './redux/slices/tableSlice';

const App = () => {
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const navigate = useNavigate();

	useEffect(() => {
		onUserDataChange(async (data) => {
			if (data?.status === UserStatus.BLOCKED) {
				dispatch(logout());
				logoutApi();

				dispatch(setIsBlocked(true));

				navigate('/auth/login');

				return;
			}

			if (data) {
				dispatch(login(data));
				getNav();
			} else {
				dispatch(logout());
				logoutApi();
			}

			dispatch(setIsInitialized(true));
		});
	}, []);

	useEffect(() => {
		const setup = async () => {
			const currentSessionId = sessionStorage.getItem('sessionId');

			if (!currentSessionId) return;

			await resumeSession(currentSessionId).catch((e) => {
				sessionStorage.removeItem('sessionId');

				console.log(e);
			});
		};

		if (isAuthenticated) setup();

		const handleUnload = async () => {
			const currentSessionId = sessionStorage.getItem('sessionId');

			if (!currentSessionId) return;

			await endSession(currentSessionId).catch(console.log);
		};

		window.addEventListener('unload', handleUnload);

		return () => {
			window.removeEventListener('unload', handleUnload);
		};
	}, [isAuthenticated]);

	useEffect(() => {
		const setup = async () => dispatch(getCountriesThunk());

		setup();
	}, []);

	return (
		<>
			<ProgressBarStyle />
			<ScrollToTop />
			<FullVersionModal />
			<VerificationRequiredModal />
			<PrivacyPolicyModal />
			<TermsAndConditionsModal />
			<AcceptableUsePolicyModal />
			<Router />
		</>
	);
};

export default App;
